<template lang="html">
  <div id="know-us-page">

    <section class="main-section">
      <div class="container">
        <h1 class="title-s1">Conoce al equipo</h1>

        <div class="row mt-4 justify-content-center">
          <div class="col-sm-6 col-lg-4 col-person" v-for="(p, pInx) in team" :key="'pInx-'+pInx">
            <div class="b-image">
              <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }"></div>
            </div>

            <h4 class="name">{{ p.name }}</h4>
            <h5 class="position">{{ p.position }}</h5>
            <p class="mt-2">
              <a class="t-150 btn-contact" target="_blank" v-if="p.phone" :href="'https://wa.me/'+p.phone"><i class="fas fa-phone"></i> Contactar</a>
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      team: [
        { imageUrl: 'public/images/pages/team/person-1.jpg', name: 'Abraham Cerda', phone: '5219993846085', position: 'Director General' },
        { imageUrl: 'public/images/pages/team/person-2.jpg', name: 'Luis Leñero', phone: '5213310462077', position: 'Director Comercial' },
        { imageUrl: 'public/images/pages/team/person-3.jpg', name: 'Eduardo Mafra', phone: '7225858358', position: 'Promotor Metepec' },
        { imageUrl: 'public/images/pages/team/person-4.jpg', name: 'Archibaldo Hope', phone: '4433370346', position: 'Promotor Morelia' },
        { imageUrl: 'public/images/pages/team/person-5.jpg', name: 'Ricardo Figueroa', phone: '7226223668', position: 'Promotor Toluca' },
        { imageUrl: 'public/images/pages/team/person-6.jpg', name: 'Ana Luisa', phone: '3316013325', position: 'Promotora' },
      ]
    }
  }
}
</script>
