<template lang="html">
  <div id="guarantees-page">

    <section class="section-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-info">
            <h4 class="title">Regulación y supervisión</h4>

            <p>
              Los bancos y las instituciones financieras que administran los fideicomisos en México están sujetos a una estricta regulación y supervisión por parte de entidades gubernamentales y organismos reguladores.<br />
              Esto garantiza que operen bajo normas y prácticas que protegen los intereses de los beneficiarios del fideicomiso.
            </p>

            <div class="box-logos">
              <img src="public/images/shared/cnbv.png">
              <img src="public/images/shared/sat.png">
              <img src="public/images/shared/bm.png">
            </div>
          </div>

          <div class="col-lg-6 mt-4 mt-lg-0 col-info">
            <h4 class="title">Profesionalismo y experiencia</h4>

            <p>
              Los bancos cuentan con personal especializado y con experiencia en la administración de fideicomisos, lo que asegura una gestión profesional y eficiente de los activos y recursos del fideicomiso.
            </p>
            <p class="mt-3">
              ﻿﻿BANCA MIFEL actualmente es nuestro banco fiduciario.
            </p>

            <!-- <div class="box-logos">
              <img class="mifel" src="public/images/shared/mifel.svg">
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <section class="section-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-info">
            <h4 class="title">Separación de activos</h4>

            <p>
              Los activos del fideicomiso se mantienen separados de los activos del banco y de la empresa de Invrengar.
            </p>
            <p class="mt-3">
              Esto significa que, en caso de insolvencia del banco o de la empresa, los activos del fideicomiso no se verán afectados y seguirán estando disponibles para los beneficiarios.
            </p>
          </div>

          <div class="col-lg-6 mt-4 mt-lg-0 col-info">
            <h4 class="title">Cumplimiento de obligaciones fiduciarias</h4>

            <p>
              Los bancos están legalmente obligados a actuar en el mejor interés de los beneficiarios del fideicomiso, cumpliendo con altos estándares de conducta fiduciaria.
            </p>
            <p class="mt-3">
              Esto incluye actuar con lealtad, diligencia y prudencia en la administración de los activos del fideicomiso.
            </p>
          </div>

          <div class="col-12 mt-3 mt-xl-5 col-info">
            <p>
              Estas características hacen que un fideicomiso bancario sea una opción segura y confiable para la administración de bienes y activos, ofreciendo tranquilidad a los fideicomitentes y beneficiarios.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
