<template lang="html">
  <div id="faqs-page">

    <FaqsComponent></FaqsComponent>

  </div>
</template>

<script>
import FaqsComponent from './faqs-section-component.vue';
export default {
  components: {
    FaqsComponent,
  },
}
</script>
