<template lang="html">
  <section class="faqs-section-component">
    <div class="container oversized-container">
      <div class="row">
        <div class="col-lg-7 col-text">
          <h2 class="title">Preguntas Frecuentes</h2>

          <div class="box-faqs" role="tablist">
            <b-card no-body v-for="(qa, qaInx) in faqs" :key="'qaInx-'+qaInx">
              <b-card-header header-tag="header" role="tab">
                <a class="btn-toggle" v-b-toggle="'accordion-'+qaInx">
                  {{ qa.q }}
                </a>
              </b-card-header>

              <b-collapse :visible="(qaInx === 0) ? true :false" :id="'accordion-'+qaInx" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>
                    {{ qa.a }}
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>

        <div class="col-lg-4 col-image">
          <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/faqs-image.jpg)' }"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        { q: '¿Cuáles son las razones para confiar en un fideicomiso y cómo pueden garantizarme un rendimiento estable?',
          a: 'Confiar en un fideicomiso de construcción tiene varias ventajas clave. En primer lugar, un fideicomiso proporciona un marco legal y estructural sólido para gestionar los proyectos de construcción, asegurando transparencia y cumplimiento de los objetivos planteados. Nuestra amplia experiencia en el sector nos ha permitido optimizar procesos y minimizar riesgos, garantizando así un rendimiento estable a nuestros inversores. Además, operamos bajo estrictas normativas y empleamos prácticas financieras prudentes que aseguran la integridad y estabilidad de los rendimientos generados.'
        },
        { q: '¿Qué garantías respaldan mi inversión en un fideicomiso y cómo se protege mi capital?',
          a: 'La inversión en un fideicomiso está respaldada por múltiples garantías que protegen su capital. Entre ellas, se incluyen la segregación de activos, lo que significa que los bienes colocados en el fideicomiso son independientes del patrimonio de la empresa. Esto asegura que, en caso de cualquier eventualidad, sus fondos estén protegidos. Además, nuestros procesos están regulados y auditados regularmente por entidades financieras confiables, garantizando transparencia y seguridad en todas las operaciones. La diversificación de proyectos y una diligente administración de los activos también contribuyen a la protección de su inversión y al aseguramiento de un rendimiento estable.'
        },
        // { q: 'En caso de decidir no continuar con mi inversión, ¿cuáles son los procedimientos o repercusiones asociadas a finalizar el fideicomiso con ustedes?',
        //   a: 'Entendemos que pueden surgir situaciones en las que desee finalizar su participación en el fideicomiso. En tal caso, contamos con un procedimiento claro y ordenado para facilitar esta transición. Inicialmente, se realizará una evaluación del valor actual de su participación y se detallarán las condiciones específicas estipuladas en el contrato del fideicomiso, incluyendo posibles penalizaciones o costos administrativos. Nos comprometemos a actuar con total transparencia y a proporcionar toda la información necesaria para que tome una decisión informada. Nuestro equipo estará a su disposición para resolver cualquier duda y asegurarse de que el proceso transcurra de manera fluida y justa.'
        // },
        { q: '¿Por cuánto tiempo han estado operando en el mercado como fideicomiso y qué entidad regulatoria supervisa sus actividades?',
          a: 'Nuestra empresa ha estado operando en el mercado de fideicomisos de construcción durante más de 20 años, lo que nos ha permitido adquirir una vasta experiencia y solidez en la gestión de estos activos. Nuestras actividades están supervisadas y reguladas por BANCO DE MEXICO, lo que garantiza que cumplimos con todas las normativas y estándares de la industria. Esta supervisión regulatoria nos ayuda a mantener altos niveles de transparencia, seguridad y confianza con todos nuestros inversores y stakeholders.'
        },
      ],
    }
  }
}
</script>
