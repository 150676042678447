<template lang="html">
  <header id="header" class="" v-bind:class="{ 'special-header' : $route.name == 'homePage' }">
    <div class="header-content">
      <b-navbar toggleable="lg" type="dark" variant="info">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo-t-white.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="far fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/empresa">Empresa</b-nav-item>
              <b-nav-item to="/garantias-y-normas">Garantía y normativas</b-nav-item>
              <b-nav-item to="/calculadora">Calculadora</b-nav-item>
              <b-nav-item to="/proyectos">Proyectos</b-nav-item>
              <b-nav-item to="/faq">F.A.Q.</b-nav-item>
              <b-nav-item to="/contacto"><i class="far fa-envelope"></i> <span class="d-lg-none">Contacto</span></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
}
</script>
