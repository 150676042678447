<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="py-5 f-w-700 page-title" style="font-size: 4rem; margin-top: 8.5rem; margin-bottom: 8.5rem;">Proximamente</h1>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/privacidad')).then((response)=>{
        this.content = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
<style lang="css" scoped>
  @media screen and (max-width: 575px) {
    .page-title {
      font-size: 2.8rem !important;
    }
  }
</style>
