<template lang="html">
  <footer id="footer">
  	<section class="newsletter-section">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-title">
						<h3>Suscríbete a nuestro newsletter</h3>
					</div>

					<div class="col-lg-6 col-form">
						<b-form @submit="onSubmit">
				      <b-form-group>
				        <b-form-input
				          v-model="formNewsletter.email"
				          type="email"
				          placeholder="Ingresa tu correo electrónico"
				          required
				        ></b-form-input>
				      </b-form-group>
							<b-button type="submit" class="btn-send">Enviar</b-button>
						</b-form>
					</div>
				</div>
			</div>
  	</section>

		<section class="locations-section">
			<div class="container">
				<div class="row box-locations" id="presence-h-section">
					<div class="col-lg-4 col-title">
						<h2>Presencia en:</h2>
					</div>

					<div class="col-sm-6 col-lg-3 col-location">
						<h4>Michoacán</h4>
						<h4>Jalisco</h4>
						<h4>Edo. de México</h4>
						<h4>Colima</h4>
						<h4>Zacatecas</h4>
					</div>

					<div class="col-sm-6 col-lg-3 col-location">
						<h4>CDMX</h4>
						<h4>Guanajuato</h4>
						<h4>Nuevo León</h4>
						<h4>Puebla</h4>
					</div>
				</div>

				<div class="row box-extra-info" id="numbers-h-section">
					<div class="col-lg-3 col-info">
						<h3>+ 600</h3>
						<hr />
						<h4>Clientes</h4>
					</div>

					<div class="col-lg-5 col-info">
						<h3>500 millones</h3>
						<hr />
						<h4>En administración</h4>
					</div>

					<div class="col-lg-3 col-info">
						<h3>+ 150</h3>
						<hr />
						<h4>Asesores</h4>
					</div>
				</div>
			</div>
		</section>

		<section class="regulations-section">
			<div class="container">
				<div class="row">
	        <div class="col-lg-12 col-text">
	          <h6>Cumpliendo normativas y recomendaciones de:</h6>
	        </div>

	        <div class="col-4 col-image">
	          <img src="public/images/shared/cnbv.png" alt="CNBV">
	        </div>

					<div class="col-4 col-image">
	          <img src="public/images/shared/sat.png" alt="SAT">
	        </div>

	        <div class="col-4 col-image">
	          <img src="public/images/shared/bm.png" alt="BM">
	        </div>
	      </div>
			</div>
    </section>

		<section class="information-section">
			<div class="container oversized-container">
				<div class="row">
					<div class="col-lg-5 col-logo">
						<h6 class="title">UN PRODUCTO DE:</h6>
						<router-link to="">
							<img src="public/images/invrengar-white.svg">
						</router-link>
					</div>

					<div class="col-sm-6 col-lg col-footer">
						<h6 class="title">LEGAL</h6>

						<p class="mb-sm-1">
							<router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
						</p>
						<!-- <p class="mb-sm-1">
							<router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
						</p> -->
						<!-- <p>
							<router-link to="">Afiliaciones</router-link>
						</p> -->
            <p>
							<router-link to="/equipo">Conócenos</router-link>
						</p>
					</div>

					<div class="col-sm-6 col-lg col-footer">
						<h6 class="title">DIRECCIÓN</h6>

						<p class="f-095">
							TORRE MAGNIA <strong>CORPORATE</strong> CENTER<br />
							Calle 15, no. 503, Piso 7, Interior 703<br />
							Fracc. Altabrisa Mérida, Yucatán C.P. 97130
						</p>
					</div>

					<div class="col-12 col-copy">
						<h6 class="txt-copy">© INVRENGAR - PiedrAngular</h6>
					</div>
				</div>
			</div>
		</section>
	</footer>
</template>

<script>
export default {
	data() {
		return {
			formNewsletter: {
				email: null
			},
		}
	},

	methods: {
    onSubmit(event) {
      event.preventDefault();
    },
	},
}
</script>
