<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-info">
            <h2 class="title">Empresa</h2>

            <p class="p-lg">
              En PiedrAngular, entendemos que el mundo de las inversiones puede ser complejo y desafiante. Por eso, nuestro equipo de expertos se dedica a analizar y seleccionar cuidadosamente los desarrollos inmobiliarios más prometedores en México, asegurando que cada proyecto no solo cumpla con los estándares más altos de calidad, sino que también ofrezca un excelente rendimiento y sostenido a nuestros inversionistas, así como tener un esquema de negocio atractivo y bien remunerado para nuestros clientes.
            </p>
          </div>

          <div class="col-lg-6 col-image">
            <div class="box-image">
              <img src="public/images/pages/about-us/about-img.png">
            </div>
          </div>

          <!-- <div class="col-lg-5 offset-xl-1 col-carousel">
            <swiper class="swiper" :options="aboutOptions">
              <swiper-slide>
                <div class="box-element-info">
                  <div class="b-images">
                    <img src="public/images/pages/home/about-us-img-1.jpg">
                  </div>

                  <div class="b-text">
                    <h4>
                      Arquitectos, ingenieros, abogados, fiscalistas, son parte del equipo de profesionales que nos respaldan.
                    </h4>

                    <p class="mt-4 text-right">
                      <router-link class="link-more" to="">Conócelos <i class="fas fa-arrow-alt-circle-right icon"></i></router-link>
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div> -->
        </div>
      </div>
    </section>

    <section class="vision-section">
      <div class="container oversized-container">
        <div class="box-plans">
          <!-- <h2 class="text-center title-2">Nosotros</h2> -->

          <!-- <p class="p-md text-center">
            xx
          </p> -->

          <div class="row row-plans">
            <div class="col-lg-6 col-plan">
              <h6 class="title">Visión</h6>
              <p>
                Nuestra visión es ser la empresa número uno en inversiones en México, que sea reconocida por nuestra capacidad para transformar el capital de nuestros clientes y crear oportunidades, que nos reconozcan por el cumplimiento efectivo de nuestros compromisos.
              </p>
            </div>

            <div class="col-lg-6 col-plan">
              <h6 class="title">Misión</h6>
              <p>
                En PiedrAngular, nuestra misión es transformar el panorama de inversiones en México mediante la implementación de fideicomisos bancarios sólidos y confiables. Nos dedicamos a identificar y desarrollar proyectos inmobiliarios que no solo generen rendimientos atractivos, sino que también contribuyan al crecimiento sostenible y al bienestar de las comunidades en las que operamos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container info-section">
      <h2 class="text-center title-2">Nosotros</h2>

      <div class="box-text">
        <p>
          Somos una empresa mexicana que nació en el año 2020 comprometida con el desarrollo y crecimiento de inversiones en el sector inmobiliario, dedicada a captar y transformar el capital de nuestros clientes. Nuestro enfoque se centra en proporcionarles oportunidades únicas a través de fideicomisos bancarios, garantizando así la seguridad y rentabilidad de sus inversiones.
        </p>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
