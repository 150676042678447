<template lang="html">
  <div id="projects-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="box-top">
          <h1 class="page-title">Proyectos</h1>

          <div class="d-block mt-4 text-center">
            <a class="btn my-1 my-sm-2 btn-s1" target="_blank" href="public/pdfs/BROCHURE-V1.pdf">Chelem Yucatán V1</a>
            <a class="btn my-1 my-sm-2 btn-s1" target="_blank" href="public/pdfs/BROCHURE-V3.pdf">Chelem Yucatán V3</a>
            <router-link class="btn my-1 my-sm-2 btn-s1" to="/proximamente">Altozano</router-link>
            <router-link class="btn my-1 my-sm-2 btn-s1" to="/proximamente">Grupo Rhada</router-link>
            <router-link class="btn my-1 my-sm-2 btn-s1" to="/proximamente">Departamentos Residenciales</router-link>
          </div>

          <div class="no-gutters box-gallery">
            <div class="col-6 col-md-6 col-lg-4 col-xl-3 col-image" v-for="(image, imageIndex) in items" :key="imageIndex">
              <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+image+')' }" @click="index = imageIndex">
                <img src="public/images/shared/empty.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="items"
      :index="index"
      :effect="'fade'"
      @close="index = null">
    </CoolLightBox>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      items: [
        'public/images/pages/companies/image-1.jpg',
        'public/images/pages/companies/image-2.jpg',
        'public/images/pages/companies/image-3.jpg',
        // 'public/images/pages/companies/image-4.jpg',
        'public/images/pages/companies/image-5.jpg',
        'public/images/pages/companies/image-6.jpg',
        'public/images/pages/companies/image-7.jpg',
        'public/images/pages/companies/image-8.jpg',
        'public/images/pages/companies/image-9.jpg',
        'public/images/pages/companies/image-10.jpg',
        'public/images/pages/companies/image-11.jpg',
        'public/images/pages/companies/image-12.jpg',
        'public/images/pages/companies/image-13.jpg',
        'public/images/pages/companies/image-14.jpg',
        'public/images/pages/companies/image-15.jpg',
        'public/images/pages/companies/image-16.jpg',
        'public/images/pages/companies/image-17.jpg',
        'public/images/pages/companies/image-18.jpg',
        'public/images/pages/companies/image-19.jpg',
        'public/images/pages/companies/image-20.jpg',
        'public/images/pages/companies/image-21.jpg',
        'public/images/pages/companies/image-22.jpg',
        'public/images/pages/companies/image-23.jpg',
        'public/images/pages/companies/image-24.jpg',
        // 'public/images/pages/companies/image-25.jpg',
        'public/images/pages/companies/image-26.jpg',
      ],
      index: null,
    }
  },
}
</script>
