<template lang="html">
  <div id="calculator-page">

    <section class="main-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-content">
            <h2 class="page-title">Calculadora</h2>

            <div class="box-calculator">
              <h5 class="mb-1">PLAN PPM Y P15</h5>
              <!-- <h5>PLAN DE RENTAS MENSUALES</h5> -->

              <div class="box-text">
                <p v-if="planType == 'PPM'">
                  En PiedrAngular te queremos recibir con los brazos abiertos. Te preparamos una proyección en la cual podrás ver como se comportará tu inversión, visualizar como te vamos a pagar mes con mes tus rendimientos, eso te ayudará a fijar tus objetivos y así poder disfrutar de los beneficios de que tu dinero trabaje para ti.
                </p>
                <p v-if="planType == 'P15'">
                  En PiedrAngular te queremos recibir con los brazos abiertos. Te preparamos una proyección en la cual podrás ver como se comportará tu inversión, visualizar como te vamos a pagar cada 15 meses tus rendimientos, eso te ayudará a fijar tus objetivos y así poder disfrutar de los beneficios de que tu dinero trabaje para ti.
                </p>
              </div>

              <div class="box-invested">
                <span class="_txt">TIPO DE PLAN</span>
                <div class="b-mount">
                  <b-form-select class="_options" v-model="planType">
                    <b-form-select-option value="PPM">PPM</b-form-select-option>
                    <b-form-select-option value="P15">P15</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

              <div class="box-invested">
                <span>MONTO A INVERTIR</span>
                <div class="b-mount">
                  <span>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(amount) }}</span>
                  <b-form-input
                    v-model="amount"
                    type="text"
                    placeholder=""
                    required
                    @keypress="isNumber($event)"
                    @paste.prevent
                    id="inputCalculator"
                    @click="onClicked"
                  ></b-form-input>

                  <div class="note">
                    Monto minimo de inversión, desde <strong v-if="planType == 'PPM'">$500,000</strong> <strong v-if="planType == 'P15'">$200,000</strong>
                  </div>
                </div>
              </div>

              <!-- Plan PPM -->
              <div class="box-table" v-if="planType == 'PPM'">
                <table>
                  <thead>
                    <tr>
                      <th class="c-1">PLAZO</th>
                      <th class="c-2">PORCENTAJE DE RENDIMIENTO</th>
                      <th class="c-3">PAGO MENSUAL</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>24</td>
                      <td>20%</td>
                      <td>
                        <span v-if="amount >= 2000000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults.month24) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>36</td>
                      <td>40%</td>
                      <td>
                        <span v-if="amount >= 2000000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults.month36) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>48</td>
                      <td>65%</td>
                      <td>
                        <span v-if="amount >= 1000000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults.month48) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>60</td>
                      <td>95%</td>
                      <td>
                        <span v-if="amount >= 500000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults.month60) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--  -->

              <!-- Plan P15 -->
              <div class="box-table" v-if="planType == 'P15'">
                <table>
                  <thead>
                    <tr>
                      <th class="c-0">CICLO</th>
                      <th class="c-1_2">PLAZO</th>
                      <th class="c-2_2">PORCENTAJE DE RENDIMIENTO</th>
                      <th class="c-3">RENDIMIENTO</th>
                      <th class="c-4">TOTAL RECIBIDO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>15</td>
                      <td>{{ p15Percentages[0].text }}</td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount1) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount1Total) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>15</td>
                      <td>{{ p15Percentages[1].text }}</td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount2) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount2Total) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>15</td>
                      <td>{{ p15Percentages[2].text }}</td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount3) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount3Total) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>15</td>
                      <td>{{ p15Percentages[3].text }}</td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount4) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount4Total) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>15</td>
                      <td>{{ p15Percentages[4].text }}</td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount5) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                      <td>
                        <span v-if="amount >= 200000">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tableResults2.amount5Total) }}</span>
                        <span class="not-valid" v-else>Monto no válido</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--  -->

              <div class="pt-2 mt-0 box-text" v-if="planType == 'PPM'">
                <p class="small">
                  * Plazo de 60 meses disponible desde los $500,000.
                </p>
                <p class="small">
                  * Plazo de 48 meses disponible a partir de 1MDP y planes de 36 y 24 meses a partir de 2MDP.
                </p>
              </div>

              <div class="pt-2 mt-4 box-text">
                <p v-if="planType == 'PPM'">
                  Al final de cada temporalidad, el fideicomiso al cual se transfirió el recurso, hará la devolución de el capital a tu cuenta y PiedrAngular los rendimientos generados durante los 24, 36, 48 o 60 meses, esa es una gran ventaja de invertir con nosotros, la honestidad y claridad de nuestros productos hacen la diferencia.
                </p>
                <p v-if="planType == 'P15'">
                  A finales del mes 15, el fideicomiso al cual se transfirió el recurso, hará la devolución de el capital a tu cuenta y PiedrAngular los rendimientos generados durante cada ciclo, esa es una gran ventaja de invertir con nosotros, la honestidad y claridad de nuestros productos hacen la diferencia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 1234,
      amount: 500000,
      planType: 'PPM',

      tableResults: {
        month24: 0,
        month36: 0,
        month48: 0,
        month60: 0,
      },

      p15Percentages: [
        { text: '18%' },
        { text: '18%' },
        { text: '18%' },
        { text: '18%' },
        { text: '18%' },
      ],

      tableResults2: {
        amount1: 0,
        amount2: 0,
        amount3: 0,
        amount4: 0,
        amount5: 0,
        amount1Total: 0,
        amount2Total: 0,
        amount3Total: 0,
        amount4Total: 0,
        amount5Total: 0,
      },
    }
  },

  methods: {
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      let letterCount = (this.amount.toString()).replace(/\s+/g, '').length;

      if(letterCount <= 9) {
        // Permitir solo un punto
        // if ( charCode === 46 && (this.amount.indexOf('.') == this.amount.lastIndexOf('.')) ) {
        //   evt.preventDefault();
        // }
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
          // && charCode !== 46
        ) {
          evt.preventDefault();
        }
      } else {
        evt.preventDefault();
      }
    },

    getP15Percentage(n, arrayPosition) {
      if(n >= 200000 && n <= 500000) {
        this.p15Percentages[arrayPosition] = { text: '18%' };
        return 0.18;
      }
      if(n >= 500001 && n <= 1500000) {
        this.p15Percentages[arrayPosition] = { text: '20%' };
        return 0.20;
      }
      if(n >= 1500001 && n <= 2500000) {
        this.p15Percentages[arrayPosition] = { text: '21%' };
        return 0.21;
      }
      if(n >= 2500001 && n <= 3500000) {
        this.p15Percentages[arrayPosition] = { text: '22%' };
        return 0.22;
      }
      if(n >= 3500001 && n <= 4500000) {
        this.p15Percentages[arrayPosition] = { text: '23%' };
        return 0.23;
      }
      if(n >= 4500001 && n <= 5500000) {
        this.p15Percentages[arrayPosition] = { text: '24%' };
        return 0.24;
      }
      if(n >= 5500001) {
        this.p15Percentages[arrayPosition] = { text: '25%' };
        return 0.25;
      }
    },

    calculate(n) {
      // Plan PPM
      this.tableResults.month24 = (0.2 / 24) * n;
      this.tableResults.month36 = (0.4 / 36) * n;
      this.tableResults.month48 = (0.65 / 48) * n;
      this.tableResults.month60 = (0.95 / 60) * n;

      // Plan P15
      this.tableResults2.amount1 = parseInt(n) * ( this.getP15Percentage(n, 0) );
      this.tableResults2.amount1Total = parseInt(n) + this.tableResults2.amount1;

      this.tableResults2.amount2 = this.tableResults2.amount1Total * ( this.getP15Percentage(this.tableResults2.amount1Total, 1) );
      this.tableResults2.amount2Total = this.tableResults2.amount1Total + this.tableResults2.amount2;

      this.tableResults2.amount3 = this.tableResults2.amount2Total * ( this.getP15Percentage(this.tableResults2.amount2Total, 2) );
      this.tableResults2.amount3Total = this.tableResults2.amount2Total + this.tableResults2.amount3;

      this.tableResults2.amount4 = this.tableResults2.amount3Total * ( this.getP15Percentage(this.tableResults2.amount3Total, 3) );
      this.tableResults2.amount4Total = this.tableResults2.amount3Total + this.tableResults2.amount4;

      this.tableResults2.amount5 = this.tableResults2.amount4Total * ( this.getP15Percentage(this.tableResults2.amount4Total, 4) );
      this.tableResults2.amount5Total = this.tableResults2.amount4Total + this.tableResults2.amount5;
    },

    onClicked() {
      setTimeout(function(){
        const input = document.querySelector('#inputCalculator');
        input.selectionStart = input.selectionEnd = input.value.length;
        input.focus();
      }, 0)
    },
  },

  mounted() {
    this.calculate(500000);
  },

  watch: {
    amount(val, oldVal) {
      this.calculate(val);
    }
  },
}
</script>
